import React from 'react';
import { Link } from 'react-router-dom'; 
import './Footer.css';

function Footer() {
    return (
        <footer>
            <p>© 2024 Speci</p>
            <span>|</span>
            <Link to="/terms" className="footer-link">Terms and Privacy</Link>
        </footer>
    );
}

export default Footer;