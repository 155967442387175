import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import './Terms.css';
import Navbar from './Navbar';

function Terms() {
  return (
    <div className="parent">
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Terms and Privacy</title>
      </Helmet>

      <Navbar />
      <div className="container">
        <h1>Terms and Privacy</h1>
        <h2 style={{ paddingTop: '20px' }}>1. Introduction</h2>
        <p>Welcome to Quick File Convert. We are
          committed to protecting
          your personal information and your right to privacy.</p>

        <h2>1. How We Use Your Information</h2>
        <p>We handle information as follows: 1. Data Retention: Submitted
          data and generated files are kept for a maximum of one hour
          before being permanently deleted; 2.Data Sharing: We do not
          share or access your submitted data or generated files, except
          when required by law enforcement; 3.Data Usage for Improvement:
          To enhance our conversion service, we may collect and analyze
          metadata from your requests; 4.Google Advertising and Analytics:
          Google collects data and uses cookies for ad personalization and
          website measurement. Learn more about Google's data practices.
          You can disable ad personalization in your Google account
          settings. We also use cookies for statistical purposes; 5.By
          using this site, you agree to our use of cookies.</p>

        <h2>2 Sharing Your Information</h2>
        <p>We only share and disclose your information in the following
          situations: 1. Compliance with Laws. We may disclose your
          information where
          we are legally required to do so in order to comply with
          applicable law, governmental requests, a judicial
          proceeding, court order, or legal process, such as in
          response to a court order or a subpoena (including in
          response to public authorities to meet national security or
          law enforcement requirements); 2. Vital Interests and Legal
          Rights. We may disclose your
          information where we believe it is necessary to investigate,
          prevent, or take action regarding potential violations of
          our policies, suspected fraud, situations involving
          potential threats to the safety of any person, and illegal
          activities, or as evidence in litigation in which we are
          involved.</p>

        <h2>3. Cookies and Other Tracking Technologies</h2>
        <p>We may use cookies and similar tracking technologies (like web
          beacons and pixels) to access or store information. Specific
          information about how we use such technologies and how you can
          refuse certain cookies is set out in our Cookie Notice.</p>

        <h2>4. Updates to This Privacy Policy</h2>
        <p>We may update this privacy notice from time to time in order to
          stay compliant with relevant laws. The updated version will be
          indicated by an updated "Revised" date and the updated version
          will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by
          directly sending you a notification.</p>
        <h2>5. Consent</h2>
        <p style={{paddingBottom:"100px"}}>By using our website, you hereby consent to our Privacy Policy
          and agree to its terms.</p>
      </div>
      <Footer />
    </div>
  );
}

export default Terms;  