import React from 'react';  
import ReactDOM from 'react-dom';  
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';    
import './index.css';  
import App from './App';  
import Terms from './Terms';  
import reportWebVitals from './reportWebVitals';  
import 'font-awesome/css/font-awesome.min.css';  
  
ReactDOM.render(  
  <React.StrictMode>  
    <Router>  
      <Routes>  
        <Route path="/" element={<App title="JPG to PDF"/>} />
        <Route path="/jpg-to-pdf" element={<App title="JPG to PDF"/>} />  
        <Route path="/jpeg-to-pdf" element={<App title="JPEG to PDF"/>} />
        <Route path="/png-to-pdf" element={<App title="PNG to PDF"/>} /> 
        <Route path="/terms" element={<Terms />} />  
      </Routes>  
    </Router>  
  </React.StrictMode>,  
  document.getElementById('root')  
);  
  
// If you want to start measuring performance in your app, pass a function  
// to log results (for example: reportWebVitals(console.log))  
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals  
reportWebVitals();  
