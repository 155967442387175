import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  return (
    <div className="navbar">
      <Link to="/" className="logo">
        <img src="logo512.png" style={{ height: "55px", width: "55px", borderRadius: "10px" }} alt="Logo" />
        <span>Quick File Convert</span>
      </Link>
    </div>
  );
}

export default Navbar;